<template>
  <v-main>
    <introNavigation />
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-4 mx-auto">
            <v-toolbar dark color="primary">
              <v-toolbar-title
                >{{ $t('adminLogin.logInYourAgentAccount') }}</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="email"
                        :rules="[rules.required]"
                        :label="$t('common.email')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show1 ? 'text' : 'password'"
                        :label="$t('common.password')"
                        @click:append="show1 = !show1"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center align-center">
              <v-btn
                align-center
                justify-center
                color="primary"
                @click="onLogin"
                >{{ $t('common.login') }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import IntroNavigation from "Components/IntroNavigation";
import functionUtils from "Utils/functionUtils";
import { mapActions } from "vuex";
export default {
  components: {
    IntroNavigation,
  },
  data() {
    return {
      valid: false,
      show1: false,
      password: "",
      email: "",
      rules: {
        required: (value) => !!value || this.$t('login.thisFieldIsRequired'),
      },
    };
  },
  methods: {
    onLogin: function() {
      if (this.$refs.form.validate()) {
        let filter = {
          email: this.email,
          password: this.password
        };
        this.ENTITY_LOGIN(filter)
          .then(
            function() {
              this.$toast.open({
                message: this.$t('common.success'),
                type: "success",
                ...this.$toastConfig,
              });
              this.$router.push({
                name: "ProductOrderManagement",
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText;
                this.$toast.open({
                  message: errorText,
                  type: "error",
                  ...this.$toastConfig,
                });
              } else {
                this.$toast.open({
                  message: this.$t('common.somethingWentWrong'),
                  type: "error",
                  ...this.$toastConfig,
                });
              }
            }.bind(this)
          );
      }
    },
    ...mapActions(["ENTITY_LOGIN"]),
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
